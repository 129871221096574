<template>
    <Contractors />
</template>

<script>
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        Contractors: () => import('@apps/Contractors')
    }
}
</script>
